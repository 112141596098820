import React, { useMemo } from "react";

import { Box } from "~components/Box";
import { PhoneMockup } from "~components/PhoneMockup";
import { SectionHeader } from "~components/SectionHeader";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimIndustryCrypto } from "~animations/industries/AnimIndustryCrypto";
import { AnimIndustryEcommerce } from "~animations/industries/AnimIndustryEcommerce";
import { AnimIndustryFinancialServices } from "~animations/industries/AnimIndustryFinancialServices";
import { AnimIndustryTravel } from "~animations/industries/AnimIndustryTravel";
import { MOCK_UI_SCREENS } from "~animations/mock_ui/MockUiMap";
import { AnimHeroData } from "~animations/products_addons/AnimHeroData";
import { AnimHeroPayments } from "~animations/products_addons/AnimHeroPayments";
import { AnimHeroPayouts } from "~animations/products_addons/AnimHeroPayouts";
import { AnimHeroSignup } from "~animations/products_addons/AnimHeroSignup";
import { AnimHeroVerification } from "~animations/products_addons/AnimHeroVerification";
import { AnimHeroVrp } from "~animations/products_addons/AnimHeroVrp";
import { AnimatedHeroWrapper } from "~animations/products_addons/_AnimatedHeroWrapper";

import type { ElementType } from "react";
import type { AnimLayerAlertProps } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";
import type { AnimLayerNotificationProps } from "~animations/__layers__/mock_ui_components/AnimLayerNotification";
import type { AnimatedHeroIllustration } from "~animations/products_addons/types";
import type { SectionHeaderProps } from "~components/SectionHeader";
import type { VideoProps } from "~components/Video";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface HeroWithAnimationProps {
  heroCommon: Array<StoryblokBlok & SectionHeaderProps>;
  animation: Array<
    StoryblokBlok & AnimatedHeroIllustration & Record<string, unknown>
  >;
  animLayerAlert: Array<StoryblokBlok & AnimLayerAlertProps>;
  animLayerNotification: Array<StoryblokBlok & AnimLayerNotificationProps>;
  video: Array<StoryblokBlok & VideoProps>;
}

const PRODUCT_ANIMATION_MAP: Record<string, ElementType> = {
  /** @todo: [MW-1307] Reflect updated naming scheme for anims in storyblok  */
  AnimHeroPayments,
  AnimHeroData,
  AnimHeroVrp,
  AnimHeroPayouts,
  AnimHeroVerification,
  AnimHeroSignup,
  AnimIndustryFinancialServices,
  AnimIndustryCrypto,
  AnimIndustryTravel,
  AnimIndustryEcommerce,
} as const;

export const getProductAnimation = ({
  component,
}: StoryblokBlok): ElementType | null | undefined => {
  if (!component) return null;

  return PRODUCT_ANIMATION_MAP[component];
};

export const getScreenAnimation = ({
  component,
}: StoryblokBlok): ElementType | null | undefined => {
  return MOCK_UI_SCREENS[component as keyof typeof MOCK_UI_SCREENS];
};

const ANIMATION_DISPLAY_PROPS = {
  mobile: "none",
  desktop: "block",
} as const;

export function HeroWithAnimation({
  heroCommon: heroCommonBlokArray,
  animation: animationBlokArray,
  animLayerAlert,
  animLayerNotification,
  video,
}: HeroWithAnimationProps) {
  const [heroCommon] = heroCommonBlokArray || [];
  const [animation] = animationBlokArray || [];

  const ProductAnimationComponent = useMemo(() => {
    if (animation) return getProductAnimation(animation);

    return null;
  }, [animation]);

  const ScreenAnimationComponent = useMemo(() => {
    if (ProductAnimationComponent) return null;
    if (animation) return getScreenAnimation(animation);

    return null;
  }, [animation, ProductAnimationComponent]);

  const { clipPath, ...animationProps } = animation || {};

  return (
    <Box
      alignItems="center"
      display="flex"
      gap="gutterWidth"
      marginBottom="spacing10"
    >
      {heroCommon && (
        <StoryblokEditable {...heroCommon}>
          <SectionHeader
            fontFamily="formula"
            textAppearance="h1"
            maxWidth="gridSpan8"
            {...heroCommon}
          />
        </StoryblokEditable>
      )}
      {animation && ProductAnimationComponent && (
        <Box
          display={ANIMATION_DISPLAY_PROPS}
          flexShrink="0"
          aspectRatio="square"
          position="relative"
          width="gridSpan5"
        >
          <StoryblokEditable {...animation}>
            <AnimatedHeroWrapper
              video={video}
              animLayerAlert={animLayerAlert}
              animLayerNotification={animLayerNotification}
              clipPath={clipPath}
            >
              <ProductAnimationComponent {...animationProps} />
            </AnimatedHeroWrapper>
          </StoryblokEditable>
        </Box>
      )}

      {animation && ScreenAnimationComponent && (
        <Box
          display={ANIMATION_DISPLAY_PROPS}
          flexShrink="0"
          aspectRatio="square"
          position="relative"
          width="gridSpan5"
        >
          <StoryblokEditable {...animation}>
            <AnimatedHeroWrapper
              video={video}
              animLayerAlert={animLayerAlert}
              animLayerNotification={animLayerNotification}
              clipPath={clipPath}
            >
              <PhoneMockup>
                <ScreenAnimationComponent {...animationProps} />
              </PhoneMockup>
            </AnimatedHeroWrapper>
          </StoryblokEditable>
        </Box>
      )}
    </Box>
  );
}
