import React, { useEffect, useRef } from "react";

import clsx from "clsx";

import { recipeMaxWidth } from "~styles/common/recipeMaxWidth.css";

// import { VariantBackgroundColorEnum } from "~styles/common/variant.backgroundColor.css";
import { Box } from "~components/Box";
import { ImageBackground } from "~components/ImageBackground";
import { SectionHeader } from "~components/SectionHeader";
import { Stepper } from "~components/Stepper";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { Video } from "~components/Video";
import { HideChildren } from "~components/VisibilityCheck/HideChildren";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { AnimLayerAlert } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";
import { AnimLayerNotification } from "~animations/__layers__/mock_ui_components/AnimLayerNotification";

import {
  SequencedAnimationsContext,
  SequencedAnimationsContextProvider,
} from "~context/SequencedAnimationsContext";

import {
  ANIMATED,
  getElemsByMotionAttr,
  getSingleElemByMotionAttr,
} from "../../utils/motion_one/motion_attribute_selectors";
import {
  animateHero,
  animateHeroBg,
  animateHeroHeader,
  animateLeftUi,
  animateRightUi,
  animateStepperDetails,
} from "./animationUtils";
import {
  PORTAL_CONFIG,
  adaptPolygonsToPage,
  getContainerKeyframes,
  shouldResize,
} from "./portalUtils";
import * as styles from "./styles.css";
import { type Polygon, type SizeArray } from "./types";

import type { ScrollOptions } from "motion";
import type { AnimLayerAlertProps } from "~animations/__layers__/mock_ui_components/AnimLayerAlert";
import type { AnimLayerNotificationProps } from "~animations/__layers__/mock_ui_components/AnimLayerNotification";
import type { SectionHeaderProps } from "~components/SectionHeader";
import type { StepperProps } from "~components/Stepper";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

type UiElement =
  | { component: "AnimLayerNotification"; props: AnimLayerNotificationProps }
  | { component: "AnimLayerAlert"; props: AnimLayerAlertProps };

const UiElementComponentMap = {
  AnimLayerNotification,
  AnimLayerAlert,
} as const;

export interface HeroWithPhoneMockupProps {
  heroCommon: Array<StoryblokBlok & SectionHeaderProps>;
  backgroundImage?: StoryblokFieldMedia;
  backgroundVideo?: StoryblokFieldMedia;
  phoneMockup: Array<Omit<StepperProps, "current" | "previous">>;
  leftUiElement?: Array<UiElement>;
  rightUiElement?: Array<UiElement>;
}

export function HeroWithPhoneMockup({
  heroCommon: heroCommonBlokArray,
  backgroundImage,
  backgroundVideo,
  phoneMockup,
  leftUiElement: leftUiElementArray,
  rightUiElement: rightUiElementArray,
}: HeroWithPhoneMockupProps) {
  const [stepper] = phoneMockup;

  const [heroCommon] = heroCommonBlokArray || [];
  const [leftUiElement] = leftUiElementArray || [];
  const [rightUiElement] = rightUiElementArray || [];
  const LeftUiElementComponent =
    (leftUiElement?.component &&
      UiElementComponentMap[leftUiElement.component]) ||
    null;

  const RightUiElementComponent =
    (rightUiElement?.component &&
      UiElementComponentMap[rightUiElement.component]) ||
    null;

  const [backgroundReady, setBackgroundReady] = React.useState(false);

  const contentContainerRef = React.useRef<HTMLDivElement>(null);
  const textureContainerRef = React.useRef<HTMLDivElement>(null);

  const getTextureBackground = () => {
    return getSingleElemByMotionAttr<HTMLImageElement>(
      textureContainerRef,
      ANIMATED.textureBackground
    );
  };

  const [portalKeyframes, setPortalKeyframes] = React.useState<Polygon[]>([]);
  const [pageSize, setPageSize] = React.useState<SizeArray>([0, 0]);
  const [containerSize, setContainerSize] = React.useState<SizeArray>([0, 0]);
  const stepperRef = useRef<HTMLDivElement>(null);
  const heroRef = useRef(null);

  // TODO: Refactor this to split into smaller functions
  useEffect(() => {
    const heroHeader = getSingleElemByMotionAttr<HTMLElement>(
      contentContainerRef,
      ANIMATED.heroHeader
    );

    const { scrollY: scrollOffset } = window;

    const phoneOffset = stepperRef.current?.getBoundingClientRect().top;
    const phoneTop =
      stepperRef.current &&
      (getComputedStyle(stepperRef.current).top as `${number}px`);
    const animationOffset: ScrollOptions["offset"] =
      phoneOffset && phoneTop
        ? [`start ${phoneOffset + scrollOffset}px`, `start ${phoneTop}`]
        : [];

    const heroBackground = getTextureBackground();

    const leftUiComponent = getSingleElemByMotionAttr<HTMLDivElement>(
      contentContainerRef,
      ANIMATED.leftUi
    );
    const rightUiComponent = getSingleElemByMotionAttr<HTMLDivElement>(
      contentContainerRef,
      ANIMATED.rightUi
    );

    const stepperDetails = getElemsByMotionAttr<HTMLDivElement>(
      contentContainerRef,
      ANIMATED.stepperDetails
    );

    const animationOptions: ScrollOptions = {
      target: stepperRef.current || undefined,
      offset: animationOffset,
    };

    animateStepperDetails(stepperDetails, animationOptions);
    animateLeftUi(leftUiComponent, animationOptions);
    animateRightUi(rightUiComponent, animationOptions);

    const heroBackgroundOffset = heroBackground?.getBoundingClientRect().top;

    const heroContentOffset =
      contentContainerRef.current?.getBoundingClientRect().top;
    const heroContentHeight =
      contentContainerRef.current?.getBoundingClientRect().height;
    if (!heroContentHeight || !heroContentOffset)
      throw new Error("Hero content height or offset is not defined");

    const { innerWidth, innerHeight } = window;
    let windowSize: SizeArray = [innerWidth, innerHeight];

    const heroSize: SizeArray = [
      innerWidth,
      heroContentHeight + heroContentOffset + scrollOffset,
    ];

    if (
      shouldResize(windowSize, pageSize, 10) ||
      shouldResize(heroSize, containerSize, 10)
    ) {
      setPageSize(windowSize);
      setContainerSize(heroSize);
    }

    let clipPathStart;
    let clipPathMiddle;
    let clipPathEnd;

    try {
      [clipPathStart, clipPathMiddle, clipPathEnd] = adaptPolygonsToPage({
        polygons: [
          PORTAL_CONFIG.START,
          PORTAL_CONFIG.MIDDLE,
          PORTAL_CONFIG.END,
        ],
        smoothPoints: PORTAL_CONFIG.SMOOTH_POINTS,
        pageSize,
        containerSize,
      });
    } catch (error) {
      console.error("Error adapting polygons to page:", error);
    }

    if (clipPathStart !== portalKeyframes[0]) {
      setPortalKeyframes([clipPathStart, clipPathMiddle, clipPathEnd]);
    }

    const handleResize = () => {
      windowSize = [window.innerWidth, window.innerHeight];

      if (
        shouldResize(windowSize, pageSize, 10) ||
        shouldResize(heroSize, containerSize, 10)
      ) {
        setPageSize(windowSize);
        setContainerSize(heroSize);
      }
    };

    animateHeroBg(
      heroBackground,
      backgroundReady,
      portalKeyframes,
      animationOptions
    );

    animateHeroHeader(heroHeader, animationOptions);

    const containerKeyframes = getContainerKeyframes({
      portalKeyframes,
      phoneOffset,
      heroBackgroundOffset,
      scrollOffset,
    });

    animateHero(heroRef.current, containerKeyframes, animationOptions);

    window.addEventListener("resize", handleResize);

    setBackgroundReady(true); // ! video doesn't fire onLoad event
    // Clean up the event listener on component unmount

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [backgroundReady, pageSize, containerSize, portalKeyframes]);

  /**
   * Necessary for image background
   */
  const handleOnLoad = () => {
    if (!backgroundReady) {
      const texture = getTextureBackground();
      if (texture) {
        setBackgroundReady(true);
      }
    }
  };

  const stepsType = stepper?.steps?.map((step) => {
    return step.stepType;
  });

  return (
    <>
      <Box
        ref={contentContainerRef}
        width="100%"
        className={clsx(recipeMaxWidth({ maxWidth: "gridSpan12" }))}
      >
        <HideChildren when={heroCommon === undefined}>
          <StoryblokEditable>
            <SectionHeader
              data-motion={ANIMATED.heroHeader}
              fontFamily="formula"
              textAppearance={heroCommon!.textAppearance}
              maxWidth="100%"
              marginX="auto"
              zIndex="2"
              isCentered
              {...heroCommon}
            />
          </StoryblokEditable>
        </HideChildren>
        <HideChildren when={stepper === undefined}>
          <SequencedAnimationsContextProvider
            length={stepper!.steps?.length}
            stepsType={stepsType}
            orientation="none"
          >
            <SequencedAnimationsContext.Consumer>
              {({ current = 0, previous = 0, handleUserSetState }) => {
                return (
                  <Box
                    ref={heroRef}
                    zIndex="2"
                    position="relative"
                    marginTop="spacing10"
                    overflow="visible"
                    className={clsx(styles.perspective)}
                  >
                    <Stepper
                      ref={stepperRef}
                      top="spacing30"
                      className={styles.heroPhoneMockupContainer}
                      marginX="auto"
                      height="100vh"
                      current={current}
                      previous={previous}
                      stepChangeCallback={handleUserSetState}
                      {...stepper!}
                    />
                    {leftUiElement && LeftUiElementComponent && (
                      <LeftUiElementComponent
                        data-motion={ANIMATED.leftUi}
                        zIndex="2"
                        className={clsx(styles.floatingUi, styles.leftUi)}
                        {...leftUiElement}
                      />
                    )}
                    {rightUiElement && RightUiElementComponent && (
                      <RightUiElementComponent
                        data-motion={ANIMATED.rightUi}
                        zIndex="2"
                        className={clsx(styles.floatingUi, styles.rightUi)}
                        {...rightUiElement}
                      />
                    )}
                  </Box>
                );
              }}
            </SequencedAnimationsContext.Consumer>
          </SequencedAnimationsContextProvider>
        </HideChildren>
      </Box>
      <Box
        ref={textureContainerRef}
        alignItems="center"
        gap="gutterWidth"
        marginBottom="spacing10"
        marginX="auto"
        position="absolute"
        top="0"
        bottom="0"
        width="100%"
        minHeight="100vh"
        className={clsx(styles.overflowHiddenContainer)}
        overflow="visible"
      >
        <HideChildren when={!backgroundVideo}>
          <Video
            data-motion={ANIMATED.textureBackground}
            className={clsx(styles.heroTextureBackground)}
            loop
            video={backgroundVideo}
            videoClassName={clsx(styles.videoTextureBackground)}
          />
        </HideChildren>
        <ShowChildren when={!backgroundVideo && backgroundImage}>
          <ImageBackground
            data-motion={ANIMATED.textureBackground}
            image={backgroundImage!}
            className={clsx(styles.heroTextureBackground)}
            onLoad={handleOnLoad}
          />
        </ShowChildren>
      </Box>
    </>
  );
}
